<template>
  <div v-if="!isLoadingPage">
    <div v-if="permission !== null">
      <div v-if="permission.read_perm == 1">
        <div class="fluid" id="loanLIst">
          <div>
            <div class="loan-list-dev">
              <v-card elevation="0" class="px-3 py-2">
                <v-row>
                  <v-col
                    cols="12"
                    class="d-flex"
                    style="
                      position: relative;
                      padding: 0 20px;
                      text-align: center;
                      margin-top: 20px;
                    "
                  >
                    <h4
                      class="indigo--text"
                      style="font-weight: bold; margin: auto"
                    >
                      PINJAMAN
                      <v-tooltip bottom color="grey">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            x-small
                            text
                            icon
                            color="grey darken-2"
                            @click="getDataFromApi"
                          >
                            <v-icon small>mdi-refresh</v-icon>
                          </v-btn>
                        </template>
                        <span>refresh page</span>
                      </v-tooltip>
                    </h4>
                  </v-col>
                  <v-col cols="12">
                    <div>
                      <v-toolbar
                        class="loan-list-toolbar-1"
                        flat
                        style="
                          height: 60px;
                          border-radius: 10px 10px 0 0;
                          padding-top: 15px;
                        "
                      >
                        <div class="d-flex align-content-start">
                          <div
                            v-if="permission.create_perm == 1"
                            style="margin-right: 15px; height: 45px"
                            class="d-flex justify-center align-center"
                          >
                            <v-btn
                              @click="addLoan"
                              outlined
                              elevation="1"
                              color="indigo"
                              class="indigo--text font-weight-bold"
                              style="font-size: 12px; margin-bottom: 4px"
                            >
                              Ajukan Pinjaman
                            </v-btn>
                          </div>
                          <div style="width: 210px">
                            <v-autocomplete
                              v-model="paramAPI.company_id"
                              label="Company"
                              item-text="name"
                              item-value="id"
                              return-object
                              dense
                              outlined
                              clearable
                              @change="companyWatcher"
                              :items="dropdown.company"
                              :disabled="isDisableCompanyDropdown"
                            ></v-autocomplete>
                          </div>
                          <!-- <div style="width: 210px; margin-left:15px;">
                      <v-autocomplete
                        v-model="paramAPI.employee_id"
                        label="Karyawan"
                        item-text="name"
                        item-value="id"
                        return-object
                        dense
                        outlined
                        clearable
                        :items="dropdown.employee"
                      ></v-autocomplete>
                    </div> -->
                        </div>
                        <div class="loan-list-toolbar-1" style="width: 300px">
                          <v-text-field
                            v-model="paramAPI.keyword"
                            label="cari name"
                            type="search"
                            outlined
                            dense
                            append-icon="mdi-magnify"
                            style="margin-left: 10px"
                            @keyup.enter="searchEnter"
                            :disabled="loading"
                          ></v-text-field>
                        </div>
                      </v-toolbar>
                    </div>
                  </v-col>

                  <v-col cols="12">
                    <v-data-table
                      mobile-breakpoint="0"
                      fixed-header
                      height="50vh"
                      :headers="headers"
                      style="cursor: pointer"
                      :items="result"
                      :loading="loading"
                      @click:row="rowClick"
                      item-key="id"
                      :options.sync="options"
                      :footer-props="{
                        showFirstLastPage: true,
                        showCurrentPage: true,
                        itemsPerPageOptions: [1, 3, 5, 10, 15, 100]
                      }"
                      :server-items-length="totalData"
                      @update:page="updatePage"
                      @update:items-per-page="updateItemPerPage"
                    >
                      <template v-slot:[`item.no`]="{ index }">
                        <div
                          style="
                            font-size: 14px;
                            padding: 0;
                            display: flex;
                            flex-direction: row;
                          "
                        >
                          {{ index + 1 + paramAPI.offset * paramAPI.limit }}
                        </div>
                      </template>
                      <template v-slot:[`item.employee.company_id`]="{ item }">
                        <div
                          :style="`font-size:14px; padding: 0; display: flex; flex-direction: row; justify-content: center; align-items:center;`"
                        >
                          {{ getCompanyName(item.employee.company_id) }}
                        </div>
                      </template>
                      <template v-slot:[`item.amount`]="{ item }">
                        <div
                          :style="`font-size:14px; padding: 0; display: flex; flex-direction: row; justify-content: start; align-items:center;`"
                        >
                          Rp{{ priceConvert(item.amount) }}
                        </div>
                      </template>
                      <template v-slot:[`item.installment`]="{ item }">
                        <div
                          :style="`font-size:14px; padding: 0; display: flex; flex-direction: row; justify-content: start; align-items:center;`"
                        >
                          Rp{{ priceConvert(item.installment) }}
                        </div>
                      </template>
                      <template v-slot:[`item.createdAt`]="{ item }">
                        <div
                          style="
                            font-size: 12px;
                            padding: 0;
                            display: flex;
                            flex-direction: row;
                          "
                        >
                          {{ convertDate(item.created_at) }}
                        </div>
                      </template>
                      <template v-slot:[`item.updatedAt`]="{ item }">
                        <div
                          style="
                            font-size: 12px;
                            padding: 0;
                            display: flex;
                            flex-direction: row;
                          "
                        >
                          {{ convertDate(item.updated_at) }}
                        </div>
                      </template>
                      <template v-slot:[`item.action`]="{ item }">
                        <v-tooltip v-if="permission.update_perm == 1" left>
                          <template v-slot:activator="{ on, attrs }"
                            ><v-icon
                              v-bind="attrs"
                              v-on="on"
                              @click.stop="rowClick(item)"
                              small
                              color="primary"
                              class="mr-2"
                            >
                              mdi-pencil
                            </v-icon>
                          </template>
                          <span>Edit</span>
                        </v-tooltip>
                        <v-tooltip v-if="permission.delete_perm == 1" right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              small
                              color="error"
                              @click.stop="deleteItem(item)"
                            >
                              mdi-delete
                            </v-icon>
                          </template>
                          <span>Delete</span>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-card>
            </div>
          </div>
          <v-dialog v-model="settingDialog" persistent max-width="600px">
            <v-card v-if="form !== null">
              <v-card-title>
                <span class="text-h5">AJUKAN PINJAMAN</span>
              </v-card-title>
              <v-form ref="entryForm2" @submit.prevent="saveSetting">
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" class="d-flex flex-column">
                        <p
                          class="text-left"
                          style="
                            margin: 0;
                            font-size: 12px;
                            font-weight: bold;
                            color: rgba(0, 0, 0, 0.5);
                          "
                        >
                          perusahaan
                        </p>

                        <div style="height: 40px">
                          <v-autocomplete
                            v-model="form.company_id"
                            label="Company"
                            item-text="name"
                            item-value="id"
                            return-id
                            dense
                            outlined
                            clearable
                            :items="dropdown.company"
                            :disabled="isDisableCompanyDropdown"
                          />
                        </div>
                      </v-col>
                      <v-col cols="12" class="d-flex flex-column">
                        <p
                          class="text-left"
                          style="
                            margin: 0;
                            font-size: 12px;
                            font-weight: bold;
                            color: rgba(0, 0, 0, 0.5);
                          "
                        >
                          Karyawan
                        </p>
                        <div style="height: 40px">
                          <v-autocomplete
                            v-model="form.employee_id"
                            :items="items"
                            :search-input.sync="search"
                            :rules="rules.employeeRules"
                            dense
                            clearable
                            outlined
                            item-text="name"
                            item-value="id"
                            return-id
                            label="ketik nama"
                          >
                            <template v-slot:item="data">
                              <template>
                                <v-list-item-avatar>
                                  <img
                                    :src="`${data.item.photo}`"
                                    @error="imgError(data.item)"
                                  />
                                </v-list-item-avatar>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{ data.item.name }}
                                  </v-list-item-title>
                                  <v-list-item-subtitle>
                                    {{ data.item.company_name }}-{{
                                      data.item.nik
                                    }}
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </template>
                            </template>
                          </v-autocomplete>
                        </div>
                      </v-col>
                      <v-col cols="12" md="6" class="d-flex flex-column">
                        <p
                          class="text-left"
                          style="
                            margin: 0;
                            font-size: 12px;
                            font-weight: bold;
                            color: rgba(0, 0, 0, 0.5);
                          "
                        >
                          Jumlah Pinjaman
                        </p>
                        <v-text-field
                          dense
                          outlined
                          v-model="form.amount"
                          type="text"
                          style="height: 45px"
                          :rules="rules.amountRules"
                        />
                      </v-col>
                      <v-col cols="12" md="6" class="d-flex flex-column">
                        <p
                          class="text-left"
                          style="
                            margin: 0;
                            font-size: 12px;
                            font-weight: bold;
                            color: rgba(0, 0, 0, 0.5);
                          "
                        >
                          Cicilan
                        </p>
                        <v-text-field
                          dense
                          outlined
                          type="text"
                          v-model="form.installment"
                          style="height: 45px"
                          :rules="rules.installmentRules"
                        />
                      </v-col>
                      <v-col cols="12" class="d-flex flex-column">
                        <p
                          class="text-left"
                          style="
                            margin: 0;
                            font-size: 12px;
                            font-weight: bold;
                            color: rgba(0, 0, 0, 0.5);
                          "
                        >
                          Tanggal Berlaku
                        </p>
                        <v-text-field
                          dense
                          outlined
                          v-model="form.effective_date"
                          type="date"
                          name="date"
                          step="1"
                          style="height: 45px"
                          :rules="rules.dateRules"
                        />
                      </v-col>
                      <v-col cols="12" class="d-flex flex-column">
                        <p
                          class="text-left"
                          style="
                            margin: 0;
                            font-size: 12px;
                            font-weight: bold;
                            color: rgba(0, 0, 0, 0.5);
                          "
                        >
                          Keterangan Tambahan
                        </p>
                        <v-textarea
                          dense
                          auto-grow
                          outlined
                          v-model="form.description"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeSettingDialog">
                    Close
                  </v-btn>
                  <v-btn color="blue darken-1" text type="submit"> Save </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>

          <v-dialog
            v-if="detail !== null"
            transition="dialog-bottom-transition"
            max-width="800"
            v-model="loanDialog"
          >
            <v-card tile>
              <v-toolbar flat dark color="primary">
                <v-btn icon dark @click="closeDialog">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title> DETAIL PINJAMAN </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form
                  readonly
                  ref="entryForm1"
                  @submit.prevent
                  style="position: relative"
                >
                  <v-row style="padding-top: 15px">
                    <v-col cols="4" class="d-flex flex-column">
                      <p
                        class="text-left"
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Karyawan
                      </p>
                      <v-text-field
                        dense
                        :value="detail.employee.name"
                        readonly
                        style="height: 45px"
                      />
                    </v-col>
                    <v-col cols="4" class="d-flex flex-column">
                      <p
                        class="text-left"
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        NIK
                      </p>
                      <v-text-field
                        dense
                        :value="detail.employee.nik"
                        style="height: 45px"
                      />
                    </v-col>
                    <v-col cols="4" class="d-flex flex-column">
                      <p
                        class="text-left"
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Perusahaan
                      </p>
                      <v-text-field
                        dense
                        :value="getCompanyName(detail.employee.company_id)"
                        style="height: 45px"
                      />
                    </v-col>

                    <v-col cols="4" class="d-flex flex-column">
                      <p
                        class="text-left"
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Jumlah pinjaman
                      </p>
                      <v-text-field
                        dense
                        :value="'Rp' + priceConvert(detail.amount)"
                        style="height: 45px"
                      />
                    </v-col>
                    <v-col cols="4" class="d-flex flex-column">
                      <p
                        class="text-left"
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Cicilan per bulan
                        <!-- <span style="font-size: 8px;"
                    >(dipotong langsung ketika gajian)</span
                  > -->
                      </p>
                      <v-text-field
                        dense
                        :value="'Rp' + priceConvert(detail.installment)"
                        style="height: 45px"
                      />
                    </v-col>
                    <v-col cols="4" class="d-flex flex-column">
                      <p
                        class="text-left"
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Tanggal berlaku
                      </p>
                      <v-text-field
                        dense
                        :value="convertDate(detail.effective_date)"
                        style="height: 45px"
                      />
                    </v-col>
                    <v-col cols="12" class="d-flex flex-column">
                      <p
                        class="text-left"
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Keterangan
                      </p>
                      <v-textarea rows="2" dense :value="detail.description" />
                    </v-col>
                  </v-row>
                </v-form>
                <v-divider></v-divider>

                <v-card v-if="detail.loan_detail !== null">
                  <v-card-title
                    >DETAIL CICILAN<v-spacer></v-spacer>
                  </v-card-title>
                  <v-data-table
                    :headers="loanDetailHeader"
                    :items="detail.loan_detail"
                    :items-per-page="10000"
                    mobile-breakpoint="0"
                    fixed-header
                    height="50vh"
                    :footer-props="{
                      showFirstLastPage: true,
                      showCurrentPage: true,
                      itemsPerPageOptions: [50, 100]
                    }"
                  >
                    <template v-slot:[`item.payment_amount`]="{ item }">
                      <div
                        style="
                          font-size: 12px;
                          padding: 0;
                          display: flex;
                          flex-direction: row;
                        "
                      >
                        Rp{{ priceConvert(item.payment_amount) }}
                      </div>
                    </template>
                    <template v-slot:[`item.remaining_loan`]="{ item }">
                      <div
                        style="
                          font-size: 12px;
                          padding: 0;
                          display: flex;
                          flex-direction: row;
                        "
                      >
                        Rp{{ priceConvert(item.remaining_loan) }}
                      </div>
                    </template>
                    <template v-slot:[`item.payment_date`]="{ item }">
                      <div
                        style="
                          font-size: 12px;
                          padding: 0;
                          display: flex;
                          flex-direction: row;
                        "
                      >
                        {{ convertDate(item.payment_date) }}
                      </div>
                    </template>
                    <template v-slot:[`item.status`]="{ item }">
                      <div
                        :style="`font-size: 12px;
                          padding: 0;
                          display: flex;
                          flex-direction: row;
                          color:${
                            item.status === 1
                              ? 'green'
                              : item.status === 0
                              ? 'red'
                              : '-'
                          };`"
                      >
                        {{
                          item.status === 1
                            ? 'Sudah dibayar'
                            : item.status === 0
                            ? 'Belum dibayar'
                            : '-'
                        }}
                      </div>
                    </template>
                  </v-data-table>
                </v-card>
              </v-card-text>
            </v-card>
          </v-dialog>
        </div>
      </div>
    </div>

    <v-card
      v-else
      class="mx-auto"
      width="100%"
      style="
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        position: fixed;
        top: 0;
        height: 100%;
        left: 0;
        right: 0;
        background: rgba(255, 255, 255);
      "
    >
      YOU CANNOT ACCESS THIS PAGE
    </v-card>
  </div>
  <div v-else>
    <v-card
      class="mx-auto"
      width="100%"
      style="
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        position: fixed;
        top: 0;
        height: 100%;
        left: 0;
        right: 0;
        background: rgba(255, 255, 255);
      "
    >
      <v-progress-circular :size="170" :width="10" color="blue" indeterminate
        >Loading...</v-progress-circular
      >
    </v-card>
  </div>
</template>
<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
import buildType from '../../../services/buildType'
const env = process.env.VUE_APP_BUILD_TYPE
export default {
  name: 'msovertime',
  data: () => ({
    config: require('../../../services/config.json')[env],
    loanDialog: false,
    settingDialog: false,
    hrsApi: buildType.apiURL('hrs'),
    eSanqua: buildType.apiURL('esanqua'),
    oAuthApi: buildType.apiURL('oauth'),
    build: process.env.VUE_APP_BUILD_TYPE,
    permission: null,
    appLevel: null,
    userLevel: null,
    isLoadingPage: true,
    isDisableCompanyDropdown: false,
    paramAPI: {
      page: 1,
      keyword: '',
      offset: 0,
      limit: 10000,
      itemsPerPage: 10,
      sortBy: 'id',
      sortType: 'ASC',
      company_id: null,
      department_id: null,
      employee_id: null
    },
    totalData: 0,
    form: null,
    detail: null,
    isEdit: false,
    search: null,
    select: null,
    items: [],
    options: {},
    actionValue: 0,
    headers: [
      {
        text: 'No',
        value: 'no',
        align: 'left',
        sortable: false
      },
      {
        text: 'Karyawan',
        value: 'employee.name',
        sortable: false
      },
      {
        text: 'NIK',
        value: 'employee.nik',
        sortable: false
      },
      {
        text: 'Perusahaan',
        value: 'employee.company_id',
        sortable: false
      },
      {
        text: 'Jumlah Pinjaman',
        value: 'amount',
        sortable: false
      },
      {
        text: 'Cicilan',
        value: 'installment',
        sortable: false
      },
      {
        text: 'Dibuat pada',
        value: 'createdAt',
        align: 'left',
        sortable: false
      },
      {
        text: 'Diupdate pada',
        value: 'updatedAt',
        align: 'left',
        sortable: false
      },
      {
        text: 'Actions',
        value: 'action',
        align: 'left',
        sortable: false
      }
    ],
    loanDetailHeader: [
      {
        text: 'Cicilan',
        value: 'installment_seq',
        sortable: false
      },
      {
        text: 'Jumlah dibayarkan',
        value: 'payment_amount',
        sortable: false
      },
      {
        text: 'Tgl. dibayarkan',
        value: 'payment_date',
        sortable: false
      },
      {
        text: 'Sisa hutang',
        value: 'remaining_loan',
        sortable: false
      }
      // {
      //   text: 'Status',
      //   value: 'status',
      //   sortable: false
      // }
    ],
    result: [],
    loading: false,
    rules: {
      companyRules: [],
      employeeRules: [],
      amountRules: [],
      installmentRules: [],
      dateRules: []
    },
    dropdown: {
      company: [],
      employee: []
    },
    unwatchamount: null,
    unwatchinstallment: null
  }),

  async mounted() {
    this.appLevel = this.config.application.hr.userLevel
    this.appLevel.administrator =
      this.config.application.all.userLevel.administrator
    this.getDataMenu()
  },
  computed: {
    // , 'getDropdownPlant'
    ...mapGetters(['getUserProfile'])
  },
  watch: {
    search(val) {
      if (val !== null) {
        if (val !== '') {
          val && val !== this.select && this.querySelections(val)
        } else {
          this.select = null
          this.items = []
        }
      } else {
        this.select = null
        this.items = []
      }
    },
    'form.employee_id'(v) {
      console.log(v)
    }
  },
  methods: {
    ...mapActions(['menu_access', 'getMenuId']),
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    async getDataMenu() {
      this.isLoadingPage = true
      const split = this.$route.path.split('/')
      const routeModule = '/' + split[1] + '/' + split[2]
      console.log('Path>>>>>', routeModule)
      await this.getMenuId({
        keyword: this.paramAPI.keyword,
        path: routeModule
      })
        .then((result) => {
          if (result.data.status_code === '00') {
            const menuId = result.data.data[0].id
            this.userLevel = this.getUserProfile.level.find(
              ({ application }) =>
                application.id === this.config.application.hr.applicationId ||
                application.id === this.config.application.all.applicationId
            )
            console.log('userLevel>>>>>', this.userLevel)
            if (this.userLevel !== null) {
              if (
                this.getUserProfile.level.find(
                  ({ id }) => id == this.appLevel.administrator
                ) !== undefined
              ) {
                this.resetPermission({
                  data: {
                    read_perm: 1,
                    create_perm: 1,
                    update_perm: 1,
                    delete_perm: 1
                  }
                })
              } else {
                this.checkMenuAccess([menuId, this.userLevel.id])
              }
            } else {
              this.resetPermission(null)
            }
          } else {
            this.isLoadingPage = false
          }
        })
        .catch((err) => {
          console.log(err)
          this.isLoadingPage = false
        })
    },
    async checkMenuAccess(payload) {
      await this.menu_access(payload)
        .then((result) => {
          console.log('menuAccess>>>>', result)
          this.resetPermission(result)
        })
        .catch((err) => {
          console.log(err)
          this.resetPermission(null)
        })
    },
    resetPermission(val) {
      this.permission = null

      if (val !== null) {
        if (val.data !== null) {
          if (val.data.read_perm === 1) {
            this.permission = val.data
            this.initDataForLayout()
          }
        }
      }
      this.isLoadingPage = false
    },
    async initDataForLayout() {
      if (this.userLevel.is_support) {
        this.paramAPI.company_id = {
          id: 0,
          name: ''
        }
      } else {
        this.paramAPI.company_id = {
          id: Number(this.getUserProfile.employee.company.plant_id),
          name: this.getUserProfile.employee.company.name
        }
      }

      this.paramAPI.department_id = this.getUserProfile.employee.department_id

      this.initDropdown()
      await this.getDataFromApi()
    },

    startWatcher() {
      this.unwatchamount = this.$watch('form.amount', (val) => {
        var removeChar = this.form.amount.replace(/[^0-9]/g, '') // This is to remove alphabets and special characters.

        var removeDot = removeChar.replace(/\./g, '') // This is to remove "DOT"
        this.form.amount = removeDot

        var formatedNumber = this.form.amount.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ','
        )
        this.form.amount = formatedNumber
      })

      this.unwatchinstallment = this.$watch('form.installment', (val) => {
        var removeChar = this.form.installment.replace(/[^0-9]/g, '') // This is to remove alphabets and special characters.

        var removeDot = removeChar.replace(/\./g, '') // This is to remove "DOT"
        this.form.installment = removeDot

        var formatedNumber = this.form.installment.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ','
        )
        this.form.installment = formatedNumber
      })
    },
    searchEnter() {
      this.getDataFromApi()
    },
    querySelections(v) {
      let url = `${this.hrsApi}employee/dropdown?keyword=${v}`
      if (this.form.company_id !== null) {
        url = url + `&company_id=${this.form.company_id}`
      }
      axios
        .get(url)
        .then((res) => {
          console.log(res)
          this.loading = false
          if (res.data.status_code === '00') {
            const resArr = []
            for (let i = 0; i < res.data.data.length; i++) {
              resArr.push({
                id: res.data.data[i].id,
                nik: res.data.data[i].nik,
                name: res.data.data[i].name,
                company_id: res.data.data[i].company_id,
                company_name: this.getCompanyAlias(res.data.data[i].company_id),
                photo: res.data.data[i].photo
              })
            }
            // return (this.items = res.data.data)
            return (this.items = resArr)
          }
          return (this.items = [])
        })
        .catch((err) => {
          this.items = []
          return console.log(err)
        })
    },
    async initDropdown() {
      this.getDropdownCompany()
    },
    companyWatcher() {
      this.getDataFromApi()
    },
    async getDropdownCompany() {
      if (this.userLevel !== null) {
        // if (this.userLevel.is_admin !== null) {
        if (this.userLevel.user_user_level !== null) {
          if (
            this.userLevel.user_user_level.allowed_company === null ||
            this.userLevel.user_user_level.allowed_company.length === 0
          ) {
            this.dropdownCompanyApi()
          } else {
            this.dropdown.company =
              this.userLevel.user_user_level.allowed_company
            this.isDisableCompanyDropdown = false
          }
        } else {
          this.dropdownCompanyApi()
        }
        // }
      }
      // else {
      //   this.dropdownCompanyApi()
      // }
    },
    async dropdownCompanyApi() {
      if (Number(this.getUserProfile.employee.company.plant_id) !== 6) {
        this.isDisableCompanyDropdown = this.userLevel.is_support
      } else {
        this.isDisableCompanyDropdown = false
      }
      axios
        .get(`${this.eSanqua}master/universal/plant/dropdown`)
        .then((res) => {
          this.dropdown.company = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },

    async getDataFromApi() {
      this.loading = true
      await this.initDataTable().then((data) => {
        console.log(data)
        setTimeout(() => {
          this.result = data.data
          this.totalData = data.total_record
          this.loading = false
        }, 1000)
      })
    },
    async initDataTable() {
      let url = `${this.hrsApi}employee/loan/list?keyword=${
        this.paramAPI.keyword
      }&offset=${this.paramAPI.offset * this.paramAPI.limit}&limit=${
        this.paramAPI.limit
      }`
      // url = url + '&order_by=${this.paramAPI.sortBy}&order_type=${this.paramAPI.sortType}'
      if (this.paramAPI.company_id !== null) {
        url = url + `&company_id=${this.paramAPI.company_id.id}`
      }
      if (this.paramAPI.employee_id !== null) {
        url = url + `&employee_id=${this.paramAPI.employee_id}`
      }
      console.log('url >>>>', url)
      return await new Promise((resolve) => {
        axios
          .get(url)
          .then((res) => {
            console.log(res)
            resolve(res.data)
          })
          .catch((err) => {
            console.log(err)
          })
      })
    },

    rowClick(pItem) {
      console.log(pItem)
      this.detail = pItem
      // this.form = {
      //   act: 'update',
      //   id: pItem.id,
      //   enable_overtime_planning: pItem.enable_overtime_planning,
      //   allow_overtime_request_wo_planning:
      //     pItem.allow_overtime_request_wo_planning,
      //   overtime_multiplier_off_type: pItem.overtime_multiplier_off_type,
      //   company: {
      //     id: pItem.company_id,
      //     name: pItem.company_name
      //   }
      // }
      // setTimeout(() => {
      //   this.settingDialog = !this.settingDialog
      // }, 300)
      setTimeout(() => {
        this.loanDialog = true
        //   this.$router.push(`/hr/loan/detail/${pItem.id}`)
      }, 100)
    },
    closeDialog() {
      // this.form = null
      this.detail = null
      // this.isEdit = false
      setTimeout(() => {
        this.loanDialog = false
      }, 300)
    },
    addLoan() {
      this.form = {
        act: 'add',
        employee_id: null,
        amount: '0',
        installment: '0',
        effective_date: null,
        name: '-',
        description: '',
        company_id: null
      }
      setTimeout(() => {
        this.startWatcher()
        this.settingDialog = !this.settingDialog
      }, 300)
    },
    saveSetting() {
      if (this.permission.create_perm == 1) {
        this.rules.employeeRules = [(v) => !!v || 'Employee is required']
        this.rules.amountRules = [(v) => !!v || 'Amount is required']
        this.rules.installmentRules = [(v) => !!v || 'Installment is required']
        this.rules.dateRules = [(v) => !!v || 'Effective date is required']
        // this.rules.companyRules = [v => !!v || 'Company is required']
        const self = this
        setTimeout(function () {
          if (self.$refs.entryForm2.validate()) {
            // self.createNewForm()
            const form = {
              act: self.form.act,
              name: '-',
              description: self.form.description,
              // company_id: self.form.company.id,
              // company_name: self.form.company.name,

              employee_id: self.form.employee_id,
              amount: self.form.amount,
              installment: self.form.installment,
              effective_date: self.form.effective_date
            }
            form.amount = Number(form.amount.replace(/,/g, ''))
            form.installment = Number(form.installment.replace(/,/g, ''))
            if (form.act === 'update') {
              Object.assign(form, { id: self.form.id })
            }
            self.submitSetting(form)
          }
        })
      }
    },
    async submitSetting(form) {
      await axios
        .post(`${this.hrsApi}employee/loan/save`, form)
        .then(async (res) => {
          console.log(res.data)
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            this.getDataFromApi()
            this.closeSettingDialog()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'warning',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('warning', res.data.status_msg, false)
            }
          }
        })
        .catch((err) => {
          console.log(err)
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
        })
    },
    async deleteItem(pItem) {
      this.showMsgDialog(
        'question',
        'Are you sure, This data will be deleted permanently',
        true
      ).then(async (res) => {
        if (res.isConfirmed) {
          await axios
            .delete(`${this.hrsApi}employee/loan/delete/${pItem.id}`)
            .then(async (res) => {
              if (res.data.status_code !== '-99') {
                this.showMsgDialog('success', res.data.status_msg, false)
                this.getDataFromApi()
              } else {
                this.showMsgDialog('warning', res.data.status_msg, false)
              }
            })
            .catch((err) => {
              console.log(err)
              this.showMsgDialog(
                'error',
                err
                  ? 'Something went wrong, Please contact an admin!'
                  : 'Something went wrong, Please contact an admin!',
                false
              )
            })
        }
      })
    },
    closeSettingDialog() {
      this.unwatchamount()
      this.unwatchinstallment()
      setTimeout(() => {
        this.form = null
        this.settingDialog = false
      }, 300)
    },
    async updatePage(p) {
      this.paramAPI.page = p
      this.paramAPI.offset = p - 1
      await this.getDataFromApi()
    },
    async updateItemPerPage(p) {
      this.paramAPI.limit = p
      this.paramAPI.offset = 0
      await this.getDataFromApi()
    },
    convertDate(raw) {
      if (raw !== null) {
        const date = new Date(raw)
        const local = date.toLocaleString('id')
        const slice = local.slice(0, local.lastIndexOf(','))
        return slice
      } else {
        return '-'
      }
    },
    priceConvert(xVal) {
      const num = 0
      if (xVal !== undefined) {
        if (xVal !== null) {
          let round = xVal
          if (round.toString().includes('.')) {
            round = round.toFixed(2)
          }
          const val = round + ''
          let result = ''

          if (val.includes('.')) {
            const splitVal = val.split('.')
            const main = splitVal[0]
            const decimal = splitVal[1]
            result =
              main.toString().replace(/\B(?<!\\d*)(?=(\d{3})+(?!\d))/g, ',') +
              `.${decimal}`
          } else {
            result = val
              .toString()
              .replace(/\B(?<!\\d*)(?=(\d{3})+(?!\d))/g, ',')
          }
          return result
        }
      }
      return num
    },
    getCompanyName(val) {
      let name = ''
      if (val != null) {
        switch (val) {
          case 1:
            name = 'PT TIRTA MAS PERKASA'
            break
          case 2:
            name = 'PT INDOMULTIMAS PERKASA'
            break
          case 3:
            name = 'PT GRAHAMAS INTITIRTA'
            break
          case 4:
            name = 'PT KENCANA ABADI JAYA'
            break
          case 5:
            name = 'PT WAHANA INTI MAS'
            break
          case 6:
            name = 'PT SANQUA MULTI INTERNASIONAL'
            break
          case 7:
            name = 'PT TALENTA HEBA PARNITA'
            break
          case 9:
            name = 'PT GOLDENPACK PERKASA'
            break
          case 10:
            name = 'PT TIRTA MAS PERKASA (BAWEN)'
            break
          case 12:
            name = 'PT BATARI PILAR SEMESTA'
            break
          case 13:
            name = 'PT BATARI PILAR CEMERLANG'
            break
          case 14:
            name = 'PT WAHANA INTI MAS (SAGI)'
            break
          case 15:
            name = 'PT KENCANA ABADI JAYA (BANYUWANGI)'
            break
          default:
            break
        }
      }

      return name
    },
    getCompanyAlias(id) {
      switch (id) {
        case 1:
          return 'TMP'
        case 2:
          return 'IMP'
        case 3:
          return 'GIT'
        case 4:
          return 'KAJ'
        case 5:
          return 'WIM'
        case 6:
          return 'SMI'
        case 7:
          return 'THP'
        case 8:
          return 'GAI'
        case 9:
          return 'GPP'
        case 10:
          return 'TMP2'
        case 11:
          return 'INO'
        case 12:
          return 'BPS'
        case 13:
          return 'BPC'
        case 14:
          return 'WIMS'
        case 15:
          return 'KAJB'
      }
    },
    imgError(xItem) {
      const xId = xItem.id
      const xIndex = this.items.findIndex((item) => item.id === xId)
      this.items[xIndex].photo =
        'https://cdn-icons-png.flaticon.com/512/61/61173.png'
    }
  }
}
</script>
<style lang="scss">
#loanLIst {
  position: relative;
  .loan-list-dev {
    padding: 15px;
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .loan-list-toolbar-1 {
      .loan-list-toolbar-1 {
        width: 60%;
        display: flex;
        flex-direction: row;
        margin-left: auto;
      }
    }
    .activity-list-toolbar-2 {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  #loanLIst {
    .loan-list-dev {
      .loan-list-toolbar-1 {
        .loan-list-toolbar-1 {
          display: none;
        }
      }
      .activity-list-toolbar-2 {
        display: block;
      }
    }
  }
}
</style>
